import { getUserBank, delUserBank } from '@/api';
export default {
  data() {
    return {
      bankList: [],
      showCenter: false,
      bankId: ""
    };
  },
  mounted() {
    this.getBank();
  },
  methods: {
    getBank() {
      getUserBank().then(res => {
        this.bankList = res.data;
        //   console.log(res)
      });
    },
    postDelBankCard() {
      this.$toast({
        message: '加载中...',
        type: "loading",
        forbidClick: true,
        duration: 0
      });
      this.showCenter = false;
      delUserBank({
        bank_id: this.bankId
      }).then(res => {
        this.$closeToast();
        this.$toast(res.msg);
        this.getBank();
      });
    },
    openDelPop(id) {
      console.log(id);
      this.bankId = id;
      this.showCenter = true;
    }
  }
};